import { MouseEventHandler } from "react";
import React from "react";

interface CustomButtonProps extends ButtonAttrs {
  onClick?: MouseEventHandler<HTMLButtonElement>,
  children: any,
  className?: string,
  disabled?: boolean,
  type?: "button" | "submit" | "reset" | undefined,
  id?: any | undefined
  accent?: boolean
  bgOff?: boolean
  paddingOff?: boolean
  roundedOff?: boolean
}

const ButtonSecondary = ({ onClick = () => { }, id, children, className = "", disabled = false, type = "button", paddingOff = false, roundedOff = false }: CustomButtonProps) => {
  return (
    <button {...{ onClick, disabled, type, id }} className={`transition-all text-action ${!paddingOff ? "px-5 py-2 md:px-3 lg2:px-5" : ""} ${!roundedOff ? "rounded-lg" : ""} ${disabled ? "opacity-50 shadow-button secondary" : "shadow-button secondary hover:text-theme-black hover:bg-action-hover"} ${className}`}>      {children}
    </button>
  );
}

export default ButtonSecondary
