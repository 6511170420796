import React from "react"

const CloseIcon = () => {
    return (
        <svg width="16" height="16" className={`w-full h-full`} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L7.99999 7.99999M7.99999 7.99999L15 15M7.99999 7.99999L15 1M7.99999 7.99999L1 15" stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default CloseIcon