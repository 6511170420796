export const GetNumberLength = (num: number | string) => {
  if (!num) return 0

  const value = typeof num === "string" ? parseInt(num) || 0 : num.toFixed(0)

  return `${value}`.length
}

export const getNumberSymbol = (length) => {
  if (length < 3) return ""
  if (length < 6) return "k"
  if (length < 9) return "M"
  if (length < 12) return "B"
  if (length < 15) return "T"
  if (length < 18) return "P"
  if (length < 21) return "E"
  if (length < 24) return "Z"
}
