import Button from "components/platform/button";
import React, { useEffect, useState } from "react";

const AgreementPopup = () => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    let agreementStatus = localStorage.getItem('agreementStatus') === 'true';
    if (!agreementStatus) {
      setOpen(true);
      localStorage.setItem('agreementStatus', `${false}`);
    }
  }, [])

  const onClick = () => {
    localStorage.setItem('agreementStatus', `${true}`);
    setOpen(false
    );
  }

  if (!open) return null;

  return (
    <div className="bg-card h-max px-4 lg:px-8 py-6 shadow-popup z-50 transition-all w-full md:w-max-mobile pointer-events-auto">
      <div className="mb-4">
        By continuing to use our website, I agree to <a href="/Terraformer_Terms_and_Conditions_24_11_21.pdf" target="_blank" className="underline cursor-pointer hover:text-theme-lime transition-all">The Terms and Condition</a> Of The Agreement Governing The Use Of TFM,
        our <a href="/Terraformer_Privacy.pdf" target="_blank" className="underline cursor-pointer hover:text-theme-lime transition-all">Privacy Policy</a> and
        to our <a href="/Terraformer_Cookies_Policy.pdf" target="_blank" className="underline cursor-pointer hover:text-theme-lime transition-all">Cookies Policy</a>
      </div>
      <div className="w-full flex justify-center">
        <Button paddingOff onClick={onClick} className="px-4 py-2">
          Accept cookies
        </Button>
      </div>
    </div>
  );
}

export default AgreementPopup
