import React from "react";

const TwitterFlatIcon = () => {
  return (
    <svg width="44" height="45" viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_b_966:395)">
        <path fillRule="evenodd" clipRule="evenodd" d="M22 0.527344C9.84974 0.527344 0 10.3771 0 22.5273C0 34.6776 9.84974 44.5273 22 44.5273C34.1503 44.5273 44 34.6776 44 22.5273C44 10.3771 34.1503 0.527344 22 0.527344ZM21.3873 19.1705L21.3411 18.4093C21.2026 16.4362 22.4183 14.6341 24.3419 13.935C25.0497 13.6864 26.25 13.6554 27.0349 13.8729C27.3426 13.9661 27.9274 14.2768 28.3429 14.5564L29.0969 15.0691L29.9279 14.805C30.3896 14.6652 31.0051 14.4321 31.2821 14.2768C31.5437 14.137 31.7745 14.0593 31.7745 14.1059C31.7745 14.37 31.2052 15.2711 30.7281 15.7682C30.0818 16.4673 30.2665 16.5295 31.5745 16.0634C32.3593 15.7993 32.3747 15.7993 32.2208 16.0945C32.1285 16.2498 31.6514 16.7936 31.1436 17.2907C30.2818 18.1452 30.2357 18.2384 30.2357 18.953C30.2357 20.0561 29.7125 22.3553 29.1893 23.6137C28.2198 25.9752 26.1423 28.4143 24.0649 29.6416C21.1411 31.3661 17.2477 31.8011 13.97 30.7912C12.8774 30.4494 11 29.5795 11 29.4241C11 29.3775 11.5694 29.3153 12.2619 29.2998C13.7084 29.2687 15.1549 28.8648 16.386 28.1502L17.217 27.653L16.2629 27.3268C14.9087 26.8607 13.693 25.7887 13.3852 24.7789C13.2929 24.4527 13.3237 24.4371 14.1854 24.4371L15.078 24.4216L14.3239 24.0643C13.4314 23.6137 12.6158 22.8525 12.2157 22.0757C11.9233 21.5164 11.554 20.1027 11.6617 19.9939C11.6925 19.9473 12.0156 20.0405 12.385 20.1648C13.4468 20.5532 13.5853 20.46 12.9697 19.8075C11.8156 18.6268 11.4617 16.8712 12.0156 15.2089L12.2772 14.4632L13.2929 15.473C15.3703 17.5082 17.8171 18.72 20.6178 19.0773L21.3873 19.1705Z" fill="currentColor" />
      </g>
      <defs>
        <filter id="filter0_b_966:395" x="-75.1458" y="-74.6184" width="194.292" height="194.292" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImage" stdDeviation="37.5729" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_966:395" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_966:395" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

export default TwitterFlatIcon