import React from "react";

const SwitchRow = ({ className = "", children }) => {
  return (
    <div className={`bg-gray w-full flex sm:w-max mx-auto rounded-full text-sm ${className}`}>
      {children}
    </div>
  );
}

export default SwitchRow
