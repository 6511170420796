import React from "react";

const TelegramFlatIcon = () => {
  return (
    <svg width="44" height="45" viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_b_966:390)">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 22.5273C0 34.6776 9.84974 44.5273 22 44.5273C34.1503 44.5273 44 34.6776 44 22.5273C44 10.3771 34.1503 0.527344 22 0.527344C9.84974 0.527344 0 10.3771 0 22.5273ZM17.9668 32.6108L18.3411 27.0029L18.3408 27.0026L28.5423 17.7966C28.99 17.3992 28.4445 17.2054 27.8502 17.5659L15.2601 25.5088L9.82188 23.8114C8.64747 23.4518 8.63903 22.6448 10.0856 22.0646L31.2771 13.8933C32.2449 13.4539 33.1791 14.1257 32.8096 15.6071L29.2007 32.6137C28.9487 33.8222 28.2185 34.1112 27.2068 33.553L21.7092 29.4913L19.0668 32.0608C19.0584 32.0689 19.0501 32.077 19.0419 32.085C18.7463 32.3727 18.5018 32.6108 17.9668 32.6108Z" fill="currentColor" />
      </g>
      <defs>
        <filter id="filter0_b_966:390" x="-75.1458" y="-74.6184" width="194.292" height="194.292" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImage" stdDeviation="37.5729" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_966:390" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_966:390" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

export default TelegramFlatIcon