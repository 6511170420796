import { useContext } from "react"
import i18n from "../../../i18n"
import LanguageContext from "../context/language"

const useTranslate = (section: any) => {
    const { activeLanguage } = useContext(LanguageContext)
    // @ts-ignore
    const current = i18n[activeLanguage]
    const translated = (key: string) => {
        if (current && current[section] && current[section][key]) {
            return current[section][key]
        }
        else {
            return i18n.en[section][key]
        }
    }
    return { translated }
}

export default useTranslate