import React from "react"
import Card from "components/Card"
import Modal from "components/Modal"
import ConnectList from "./ConnectList"
import styles from "./ConnectList.module.scss"
import CloseIcon from "components/landing/icons/closeIcon"

const ConnectListModal = (modal: IModal) => {
  return (
    <Modal {...modal} customClasses isCloseBtn={false}
      className="relative outline-none w-full px-4 lg:px-0  w-max-sm" zIndex='z-999' >
      <Card className="px-4">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-lg text-white font-normal font-poppins ">Connect to a wallet</h1>
          <div
            onClick={() => {
              modal.close()
            }}
            className="cursor-pointer h-4 w-4 hover:bg-black-80 rounded-base p-1 content-box transition-all">
            <CloseIcon />
          </div>
        </div>
        <ConnectList />
      </Card>
    </Modal>
  )
}

export default ConnectListModal
