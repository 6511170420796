import ru from "./locales/ru"
import en from "./locales/en"
import { IDictionary } from "./locales"

export type ILanguage = "en"
// "ru" | "en" | "es" | "ar"

type I18n = {
  [key in ILanguage]
}

export const availableLanguages: ILanguage[] = ["en"]

//,"ru", "ar", "en", "es"]

const i18n: I18n = {
  // ru,
  en,
}

export default i18n
