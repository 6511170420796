import React from "react";

const Container = ({
  children,
  className = "",
  id = ""
}: { children: any, className?: string, id?: string }) => {

  return (
    <div id={id} className={`grid-container ${className}`}>
      {children}
    </div>
  )
}

export default Container
