import React from "react";

const SwitchRowItem = ({ children, onClick = () => { }, active, className = "" }) => {
    return (
        <div
            onClick={onClick}
            style={{ whiteSpace: 'nowrap' }}
            className={`px-3 py-2 rounded-full w-full text-center transition-all cursor-pointer ${active ? "bg-blue" : "hover:bg-switch"} ${className}`}
        >
            {children}
        </div>
    );
}

export default SwitchRowItem
