import React from "react"
import { useState, useEffect } from 'react';

// @ts-ignore
export default function useDebounce<T>(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);
    useEffect(
        () => {
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);
            return () => {
                clearTimeout(handler);
            };
        },
        [value]
    );

    return [debouncedValue, setDebouncedValue] as const;
}