import React, { ReactNode, useState } from 'react';
import { ILanguage } from '../../../i18n';

const LanguageContext = React.createContext({
  activeLanguage: "ru",
  setActiveLanguage: (arg: ILanguage) => { }
});

interface Props {
  children: ReactNode;
  language: ILanguage
}

const LanguageContextProvider = ({ children, language }: Props) => {
  const [activeLanguage, setActiveLanguage] = useState(language)

  return (
    <LanguageContext.Provider
      value={{
        activeLanguage,
        setActiveLanguage
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContext;

export { LanguageContextProvider };