import PageLoaderContext from "components/platform/context/pageLoader"
import React, { FC, useContext, useEffect } from "react"
import { useContractsAddressState } from "../hooks/useContractsAddress"
import { ContractsAddressProvider } from "../hooks/useContractsAddress"
import { useContractsAddressTokenState } from "../hooks/useContractsAddressToken"
import { ContractsAddressTokenProvider } from "../hooks/useContractsAddressToken"

const Contract: FC = ({ children }) => {
  const contractsAddress = useContractsAddressState()
  const contractsAddressToken = useContractsAddressTokenState()

  const { setLoading } = useContext(PageLoaderContext)

  useEffect(() => {
    setTimeout(() =>{
      if (contractsAddress)
      setLoading(false)
    },100)

  }, [contractsAddress])

  return !contractsAddress ? (
    <>
    </>
  ) : (
    <ContractsAddressProvider value={contractsAddress}>
      <ContractsAddressTokenProvider value={contractsAddressToken}>
        {children}
      </ContractsAddressTokenProvider>
    </ContractsAddressProvider>
  )
}

export default Contract
