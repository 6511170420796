import { useModal } from 'components/Modal';
import React, { ReactNode, useEffect, useState } from 'react';
import { ConfirmState } from '../confirmModal';
import { OrderAsset } from '../interfaces';

const CancelLimitModalContext = React.createContext({
  modal: null,
  confirmationStep: ConfirmState.confirming,
  setConfirmationStep: (arg: ConfirmState) => { },
  result: null,
  error: undefined,
  setResult: (arg: any) => { },
  askAsset: null,
  setAskAsset: (arg: OrderAsset) => { },
  offerAsset: null,
  setOfferAsset: (arg: OrderAsset) => { },
  onConfirm: () => { },
  setOnConfirm: (arg: () => void) => { },
});

interface Props {
  children: ReactNode;
}

const CancelLimitModalContextProvider = ({ children }: Props) => {

  const modal = useModal()

  const [onConfirm, setOnConfirm] = useState<() => void>(() => { });

  const [confirmationStep, setConfirmationStep] = useState<ConfirmState>(ConfirmState.confirming)
  const [result, setResult] = useState(null);
  const [error, setError] = useState(result instanceof Error ? result : undefined);

  const [askAsset, setAskAsset] = useState<OrderAsset>(null);
  const [offerAsset, setOfferAsset] = useState<OrderAsset>(null);

  useEffect(() => {
    setError(result instanceof Error ? result : undefined)
  }, result)

  return (
    <CancelLimitModalContext.Provider
      value={{
        modal,
        confirmationStep,
        setConfirmationStep,
        result,
        error,
        setResult,
        askAsset,
        setAskAsset,
        offerAsset,
        setOfferAsset,
        onConfirm,
        setOnConfirm
      }}
    >
      {children}
    </CancelLimitModalContext.Provider>
  );
};

export default CancelLimitModalContext;

export { CancelLimitModalContextProvider };