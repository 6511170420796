import React, { FC } from "react";
import Tippy, { TippyProps } from "@tippyjs/react";
import classNames from "classnames";
import { isNil } from "ramda";
import TooltipIconSVG from "./platform/icons/tooltip";

export const DefaultTippyProps: TippyProps = {
  animation: false,
  interactive: false,
  appendTo: document.body,
};

const TooltipTippyProps: TippyProps = {
  ...DefaultTippyProps,
  placement: "top",
  className:
    "bg-black-70 text-white rounded-base py-3 px-5 text-center text-xs-d shadow-tooltip font-poppins",
};

interface Props extends Omit<TippyProps, "children"> {
  onClick?: () => void;
}

const Tooltip: FC<Props> = ({ className, onClick, children, ...props }) => {
  const button = (
    <button
      type="button"
      className={classNames(
        "flex opacity-50 hover:opacity-100 transition-all",
        className
      )}
      onClick={onClick}
    >
      {children}
    </button>
  )

  return props.content ? (
    <Tippy
      {...TooltipTippyProps}
      {...props}
      hideOnClick={isNil(props.visible) ? false : undefined}
    >
      {button}
    </Tippy>
  ) : (
    button
  );
};

export const TooltipIcon: FC<Props> = ({ children, ...props }) => (
  <div className="flex items-center">
    <div className="mr-1-5">
      <Tooltip {...props}>
        <TooltipIconSVG />
      </Tooltip>
    </div>
    {children}
  </div>
);

export default Tooltip;
