import React from 'react'

const QuitIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.5961 6.71875L16.8773 10L13.5961 13.2812" stroke="#9B96B4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.125 10L16.875 10" stroke="#9B96B4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.375 17.5L3.75 17.5C3.58424 17.5 3.42527 17.4342 3.30806 17.3169C3.19085 17.1997 3.125 17.0408 3.125 16.875L3.125 3.125C3.125 2.95924 3.19085 2.80027 3.30806 2.68306C3.42527 2.56585 3.58424 2.5 3.75 2.5L9.375 2.5" stroke="#9B96B4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default QuitIcon