import Button from 'components/platform/button';
import { useAddress, useConnectModal } from 'hooks';
import React, { MutableRefObject, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import CloseIcon from '../icons/close';
import TelegramFlatIcon from '../icons/telegramFlat';
import TwitterFlatIcon from '../icons/twitterFlat';
import WalletButton from '../walletButton';

const MenuContext = React.createContext({
  open: false,
  setOpen: (arg: boolean) => { },
  isMobileMenuHidden: false,
  setIsMoblieMenuHidden: (arg: boolean) => { },
});

interface Props {
  children: ReactNode;
}

const points: { text: string, href: string }[] = [
  {
    text: "Trade",
    href: "/trade"
  },
  {
    text: "Earn",
    href: "/earn"
  },
  {
    text: "Launch",
    href: "/launchpad"
  },
  {
    text: "KYC",
    href: "/kyc"
  },
]

const MenuContextProvider = ({ children }: Props) => {
  const [open, setOpen] = useState(false)
  const menuRef = useRef() as MutableRefObject<HTMLDivElement>
  const location = useLocation()
  const [isMobileMenuHidden, setIsMoblieMenuHidden] = useState(false)

  const connectModal = useConnectModal()
  const address = useAddress()
  const handleClick = () => connectModal.open()
  const inRect = (elm: HTMLElement, mouse: globalThis.MouseEvent) => {
    return ((mouse.clientX >= elm.offsetLeft) && (mouse.clientX < elm.offsetLeft + elm.clientWidth))
      && ((mouse.clientY >= elm.offsetTop) && (mouse.clientY < elm.offsetTop + elm.clientHeight))
  }

  const memoOnClick = useCallback((e: globalThis.MouseEvent) => {
    setOpen(inRect(menuRef.current, e))
  }, [menuRef])

  useEffect(() => {
    const doc = document.body

    if (open) {
      doc.style.overflow = "hidden"
      window.addEventListener("click", memoOnClick)
    }
    else {
      doc.style.overflow = "auto"
      window.removeEventListener("click", memoOnClick)
    }

    return () => {
      window.removeEventListener("click", memoOnClick)
    }

  }, [open, memoOnClick])

  return (
    <MenuContext.Provider
      value={{
        open,
        setOpen,
        isMobileMenuHidden,
        setIsMoblieMenuHidden,
      }}
    >
      <div className={`transition-all duration-500 fixed z-30 ${open ? "w-screen right-0" : "w-0 -right-full"} h-screen top-0 bg-black bg-opacity-60 grid grid-cols-10 gap-4`}>
        <div ref={menuRef} className="bg-white col-span-6 col-start-5 pl-8 pr-4 pt-4 pb-10 grid-rows-max-fr bg-opacity-100">
          <div onClick={(e) => { e.stopPropagation(); setOpen(false) }} className="flex w-full justify-end ">
            <div className="text-secondary hover:text-secondary-hover transition-all duration-150 cursor-pointer">
              <CloseIcon />
            </div>
          </div>
          <div className='flex flex-col justify-between h-full'>
            <div className="text-base">
              <div className="w-full flex justify-center my-10">
                {!address ?
                  <Button className="text-white bg-black w-max" onClick={handleClick}>
                    Connect Wallet
                  </Button>
                  :
                  <WalletButton className="text-white bg-black" />
                }
              </div>
              {points.map((item, index) =>
                <Link
                  key={item.text}
                  to={item.href}
                  className={`transition-all py-3 duration-100 cursor-pointer flex hover:text-theme-blue  items-center ${location.pathname.includes(item.href) ? 'text-theme-blue' : ""} ${index !== points.length - 1 ? "pr-6" : ""}`}
                >
                  {location.pathname.includes(item.href) && <div className="h-1-5 w-1-5 bg-theme-blue  rounded-full mr-2" />}
                  {item.text}
                </Link>
              )}
            </div>
            <div className="flex -mx-1">
              <a href="https://t.me/terraformer" target="_blank" className="hover:text-theme-blue transition-all cursor-pointer mx-1">
                <TelegramFlatIcon />
              </a>
              <a href="https://twitter.com/terraformer" target="_blank" className="hover:text-theme-blue transition-all cursor-pointer mx-1">
                <TwitterFlatIcon />
              </a>
            </div>
          </div>
        </div>
      </div>
      {children}
    </MenuContext.Provider>
  );
};

export default MenuContext;

export { MenuContextProvider };
