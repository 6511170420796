import React from "react"

const TooltipIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10.0002" cy="10.0002" r="8.88889" stroke="currentColor" strokeWidth="1.5" />
      <path d="M10.2337 5.67444C10.5026 5.67444 10.7185 5.77222 10.8815 5.96778C11.0526 6.16333 11.13 6.4037 11.1137 6.68889C11.1055 6.96593 11.0118 7.20222 10.8326 7.39778C10.6533 7.58518 10.4293 7.67889 10.1604 7.67889C9.88333 7.67889 9.65925 7.58518 9.48814 7.39778C9.32518 7.20222 9.24777 6.96593 9.25592 6.68889C9.27221 6.4037 9.37814 6.16333 9.5737 5.96778C9.76925 5.77222 9.98925 5.67444 10.2337 5.67444ZM9.80592 8.75444C10.2296 8.75444 10.523 8.88481 10.6859 9.14556C10.857 9.39815 10.9426 9.74037 10.9426 10.1722C10.9426 10.3678 10.9222 10.6326 10.8815 10.9667C10.8407 11.3007 10.7959 11.6511 10.747 12.0178C10.6981 12.3844 10.6533 12.7185 10.6126 13.02C10.5718 13.3215 10.5515 13.5415 10.5515 13.68C10.5515 13.8104 10.5718 13.8959 10.6126 13.9367C10.6533 13.9774 10.6941 13.9978 10.7348 13.9978C10.8326 13.9978 10.9304 13.9285 11.0281 13.79C11.1259 13.6515 11.2278 13.3989 11.3337 13.0322L11.9937 13.2278C11.9611 13.3989 11.8959 13.5944 11.7981 13.8144C11.7085 14.0344 11.5863 14.2463 11.4315 14.45C11.2767 14.6456 11.0893 14.8085 10.8693 14.9389C10.6574 15.0693 10.413 15.1344 10.1359 15.1344C9.68777 15.1344 9.37814 15.0122 9.20703 14.7678C9.04407 14.5233 8.96258 14.1852 8.96258 13.7533C8.96258 13.517 8.98295 13.2359 9.0237 12.91C9.06444 12.5841 9.10925 12.25 9.15814 11.9078C9.21518 11.5656 9.26407 11.2478 9.30481 10.9544C9.34555 10.653 9.36592 10.4044 9.36592 10.2089C9.36592 10.0704 9.34555 9.98481 9.30481 9.95222C9.26407 9.91148 9.21925 9.89111 9.17036 9.89111C9.08073 9.89111 8.98703 9.96037 8.88925 10.0989C8.79147 10.2374 8.68962 10.49 8.5837 10.8567L7.9237 10.6611C7.95629 10.49 8.02147 10.2944 8.11925 10.0744C8.21703 9.85444 8.34333 9.64667 8.49814 9.45111C8.6611 9.24741 8.84851 9.08037 9.06036 8.95C9.28036 8.81963 9.52888 8.75444 9.80592 8.75444Z" fill="currentColor" />
    </svg>
  )
}

export default TooltipIcon