import React, { MutableRefObject, ReactNode, useEffect, useRef, useState } from 'react';

const ResizeContext = React.createContext({
  subscribeOnResize: (callaback: () => void) => { }
});

interface Props {
  children: ReactNode;
}

const ResizeContextProvider = ({ children }: Props) => {
  const resizeSubscibers = useRef(new Set<() => void>()) as MutableRefObject<Set<() => void>>

  const handleResize = (e) => {
    const html = document.querySelector("html")

    if (html.clientWidth > 1024) {
      resizeSubscibers.current.forEach((callback) => callback())
    }
  }

  const subscribeOnResize = (callaback: () => void) => {
    resizeSubscibers.current.add(callaback)
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <ResizeContext.Provider
      value={{
        subscribeOnResize
      }}
    >
      {children}
    </ResizeContext.Provider>
  );
};

export default ResizeContext;

export { ResizeContextProvider };