import React from "react"

const LoaderIcon = () => {
  return (
    <svg className="h-full" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M36 18C36 27.9411 27.9411 36 18 36C8.05887 36 0 27.9411 0 18C0 8.05887 8.05887 0 18 0C27.9411 0 36 8.05887 36 18ZM3.6 18C3.6 25.9529 10.0471 32.4 18 32.4C25.9529 32.4 32.4 25.9529 32.4 18C32.4 10.0471 25.9529 3.6 18 3.6C10.0471 3.6 3.6 10.0471 3.6 18Z" fill="#45434F" />
      <path d="M2.77501 12.4647C1.84073 12.125 1.35063 11.0872 1.78188 10.1915C2.58018 8.53345 3.62961 7.00434 4.8961 5.65951C6.57482 3.87693 8.59959 2.45561 10.8465 1.4825C13.0935 0.509387 15.5153 0.00494511 17.9639 3.61726e-05C19.8112 -0.0036673 21.6445 0.277009 23.3999 0.829068C24.3482 1.1273 24.7699 2.19474 24.3785 3.10856V3.10856C23.9871 4.02237 22.9307 4.43399 21.9752 4.15956C20.6763 3.7865 19.3285 3.59731 17.9711 3.60003C16.0123 3.60396 14.0748 4.00751 12.2772 4.786C10.4797 5.56449 8.85986 6.70155 7.51688 8.1276C6.5863 9.11575 5.8022 10.2283 5.18571 11.4308C4.7322 12.3155 3.70929 12.8044 2.77501 12.4647V12.4647Z" fill="white">
        <animateTransform attributeType="xml"
          attributeName="transform"
          type="rotate"
          from="0 18 18"
          to="360 18 18"
          dur="0.6s"
          repeatCount="indefinite" />
      </path>
    </svg>
  )
}

export default LoaderIcon